import React from "react"
import { graphql } from "gatsby"
import moment from "moment"
import { format } from 'date-fns'
import { nl } from 'date-fns/locale'

import { Wide } from "../components/layout"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Ratio from "../components/ratio"
import { TwitterLink } from "../components/social"

export const query = graphql`
  query {
    books: allBooksJson(sort: {fields: date, order: DESC}) {
      edges {
        node {
          author
          date
          id
          image
          release
          title
          url
        }
      }
    }
  }
`

const recent = (books) => {
  return books.map(book => book.node)
    .filter(book => {
      let date = moment(book.date);
      return date <= moment() && date >= moment().subtract(3, 'months').startOf('month');
    })
}

const upcoming = (books) => books.map(book => book.node).filter(book => {
  let date = moment(book.date);
  return date > moment() && 
          date <= moment().add(1, 'month').endOf('month') &&
          book.image;
}).reverse()

const Grid = ({children}) => <div className="grid gap-4 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">{children}</div>

const Book = ({book}) => (
  <a className="flex flex-col text-gray-900"
    href={book.url} target="_blank" rel="noreferrer">
    <div className="p-4 rounded-xl hover:bg-gray-200 h-full">
      <Ratio ratio={1.5}>
        <img className="w-full h-full object-cover" src={book.image} alt={book.title}/>
      </Ratio>
      <h2 className="mt-2 text-xl text-red-900 font-sans tracking-tight font-bold">{book.title}</h2>
      <p>
        {book.author}
        <br />
        {format(new Date(book.date), 'MMMM', { locale: nl })}
      </p>
    </div>
  </a>
)

const Boeken = ({data, location}) => {
  const recentBooks = recent(data.books.edges);
  const upcomingBooks = upcoming(data.books.edges);
  return (
    <Layout>
      <SEO title="Boekenkalender – Vonk" path={location.pathname} />
      <p className="px-4 max-w-2xl m-auto text-xl mt-8">Op deze pagina vind je boeken die binnenkort verschijnen en onlangs verschenen zijn. Mis je een boek? Laat het weten via <TwitterLink>Twitter</TwitterLink> of mail <strong>tom@vonkfantasy.nl</strong>.</p>
      <Wide>
        {upcomingBooks.length > 0 &&
          <>
            <h1 className="mt-8 mx-4 text-2xl font-bold font-sans uppercase">Verschijnt binnenkort</h1>
            <Grid>
              {upcomingBooks.map((book, index) => {
                return (
                  <Book key={book.id} book={book} />
                )
              })}
            </Grid>
          </>
        }
        <h1 className="mt-8 mx-4 text-2xl font-bold font-sans uppercase">Recent verschenen</h1>
        <Grid>        
          {recentBooks.map((book, index) => {
            return (
              <Book key={book.id} book={book} />
            )
          })}
        </Grid>
      </Wide>
    </Layout>
  )
}

export default Boeken
