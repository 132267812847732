import React from "react"
import { css } from "@emotion/react"

export default ({ratio, children}) => (
  <div css={css`
    position: relative;
    padding-bottom: ${ratio * 100}%;  
  `}>
    <div css={css`
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
    `}>
    {children}
    </div>
  </div>
)
